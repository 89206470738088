/**
 * Mixed keys seems to mess up the request sometimes.
 * This ensures consistant indexing
 */
export const transformSelectedRoomsToRequestReady = selectedRooms => selectedRooms
  .map(roomid => ({
    roomid,
    amount: 1,
  }))

export const transformDealFinderPackages = p => ({
  date: p.date,
  days: p.days,
  destination: p.destination,
  is_direct: p.is_direct,
  open_purchase: !!p.open_purchase,
  origin: p.origin,
  price: p.pricepp,
  total_price: p.price,
  return: p.return_date,
  supplier: p.supplier,
  hotel_code: p.hotel_code,
  tripid: p.tripid,
  recommended_flight: p.recommended_flight,
})

/**
 * Primarily used in old charter search
 * @param offer
 * @returns {*&{pricepp: (number|*), destination_country: null, giata_id: (*|number), price: (number|*), regular_pricepp: *, hotel: (string|*), accomodation_rating: number, giataId: (*|number), destination_city: (string|*)}}
 */
export const transformHotelOfferToPackage = offer => ({
  ...offer,
  id: parseInt(offer.id || 0),
  price: offer?.min_additional_cost || offer?.regular_price,
  regular_pricepp: offer?.regular_price,
  pricepp: offer?.min_additional_cost || offer?.regular_price,
  giataId: offer?.giataId || offer?.giata_id,
  giata_id: offer?.giataId || offer?.giata_id,
  hotel: offer?.meta?.PropertyName,
  accomodation_rating: parseFloat(offer?.meta?.Rating),
  destination_city: offer?.zone_name,
  destination_country: null,
})
